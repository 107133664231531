import React, { useState, useEffect } from "react";
import Footer from "../components/footer.js";
import Navbar from "../components/navbar.js";
import "aos/dist/aos.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/esm/Container.js";
import PackageHis from "../components/History/PackageHis.js";
import ReferralHis from "../components/History/ReferralHis.js";
import PlanDisplay from "../components/PlanView/PlanDisplay.js";
import TreeView from "../components/TreeView/TreeView.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CircularProgressbar } from "react-circular-progressbar";
// import Example from './Example';

import { useSelector } from "react-redux";
import Web3 from "web3";
import contractAbi from "../ABI/MLMContract.json";
import tokenAbi from "../ABI/TokenContract.json";
import config from "../config/index.js";
import moment from "moment";
import copy from "copy-to-clipboard";
import { toastAlert } from "../helper/toastAlert.js";
import { Multicall } from "ethereum-multicall";
import { convert } from "../helper/convert.js";
import { getFormatMulticall1, bignumber } from "../helper/Action.js";
import { getUserRoyaltyBalance } from "../helper/axios.acion.js";
import RoyaltyHis from "../components/History/royaltydata.js";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

export default function Dashboard() {
  const walletConnection = useSelector((state) => state?.walletConnection);
  //STATE
  const [parentId, setparentId] = useState("");
  const [refLength, setRefLength] = useState(0);
  const [directIncome, setDirectIncome] = useState(0);
  const [matIncom, setMatIncom] = useState(0);
  const [upgradeIncome, setUpgradeIncome] = useState(0);
  const [leftCount, setLeftCount] = useState(0);
  const [rightCount, setRightount] = useState(0);
  const [poolInfo, setPoolInfo] = useState([]);
  const [RoyaltyReward, setRoyaltyReward] = useState(0);
  const [claimStatus, setclaimStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [busdBalance, setbusdBalance] = useState(0);
  const [defiqBalance, setdefiqBalance] = useState(0);
  const [amountBusd, setAmountBusd] = useState("");
  const [addressBusd, setAddressBusd] = useState("");
  const [amountDefiq, setAmountDefiq] = useState("");
  const [addressDefiq, setAddressDefiq] = useState("");
  const [completedMonths, setcompletedMonths] = useState("");
  const [royCompPercentage, setroyCompPercentage] = useState("");

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);

  async function copyToClipboard(clipdata) {
    copy(clipdata);
    toastAlert("success", "Copied Successfully");
  }

  useEffect(() => {
    if (walletConnection.address) {
      let web3 = new Web3(walletConnection?.web3);
      getRoyaltyBalance();
      let contractcall = new web3.eth.Contract(contractAbi, config.Contract);
      const web3call = new Web3(walletConnection?.provider || window.ethereum);
      const multicall = new Multicall({ web3Instance: web3call });
      getDashboardDetail(web3, contractcall);
      getpooldetail(contractcall, multicall);
      console.log(walletConnection?.userdetails?.joinedDate, "asdasdasd");

      const currentDate = new Date();
      const givenDate = new Date(
        walletConnection?.userdetails?.joinedDate * 1000
      );
      const differenceInMilliseconds = currentDate - givenDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      console.log(differenceInDays, "differenceInDaysdifferenceInDays");
      const monthsCompleted = Math.floor(differenceInDays / 30);
      

      var percentage = (monthsCompleted / 18) * 100;
      percentage = Math.min(percentage, 100);
      setroyCompPercentage(percentage);
      setcompletedMonths(monthsCompleted);
    }
  }, [walletConnection?.address]);

  const getRoyaltyBalance = async () => {
    const result = await getUserRoyaltyBalance({
      address: walletConnection.address,
    });
    if (result && result.length > 0) {
      setRoyaltyReward(result?.[0].sumamount);
    }
  };

  //GET - DASHBOARD INFO
  const getDashboardDetail = async (web3, contractcall) => {
    let contractcallTokenBust = new web3.eth.Contract(
      tokenAbi,
      config.TokenContract
    );
    let contractcallTokenDefiQ = new web3.eth.Contract(
      tokenAbi,
      config.DefiQtoken
    );

    let tokenBalance = await contractcallTokenBust.methods
      .balanceOf(config.Contract)
      .call();
    setbusdBalance(tokenBalance);

    let tokenBalanceDefi = await contractcallTokenDefiQ.methods
      .balanceOf(config.Contract)
      .call();

    setdefiqBalance(tokenBalanceDefi);

    let getParentId = await contractcall.methods
      .users(walletConnection?.userdetails?.parent)
      .call();
    setparentId(getParentId.id);

    let getClaimststus = await contractcall.methods
      .checkClaimStatus(walletConnection.address)
      .call();
    setclaimStatus(getClaimststus);

    let reflength = await contractcall.methods
      .getReferrals(walletConnection.address)
      .call();
    setRefLength(reflength.length);

    let UserInc = await contractcall.methods
      .UserInc(walletConnection.address)
      .call();

    setDirectIncome(UserInc.directIncome);
    setMatIncom(UserInc.matrixIncome);
    setUpgradeIncome(UserInc.RoyaltyIncome);
  };

  //GET - POOL INFO
  const getpooldetail = async (contractcall, multicall) => {
    if (!walletConnection?.userdetails?.isExist) {
      window.location.href = "/";
    }

    let poollength = await contractcall.methods.getPoolLenght().call();
    let userCur = await contractcall.methods
      .users(walletConnection.address)
      .call();

    let leftCount = await contractcall.methods
      .leftCount(walletConnection.address)
      .call();
    setLeftCount(leftCount);

    let rightCount = await contractcall.methods
      .rightCount(walletConnection.address)
      .call();
    setRightount(rightCount);

    //GET POOL AMOUNT
    let poolAmount = await getPoolAmount(poollength, multicall);
    //GET USER POOL INFO
    let poolUserInfo = await getUserPoolInfo(
      poollength,
      multicall,
      poolAmount,
      userCur
    );
    setPoolInfo(poolUserInfo);
  };

  const claimRewards = async () => {
   
    if (!claimStatus) {
      toastAlert("error", "claim not available now");
      return false;
    }

    if (walletConnection?.userdetails?.stakingAmount / 1e18 < 0) {
      toastAlert("error", "amount is Required");
      setLoader(false);
      return false;
    }

    setLoader(true);

    let web3 = new Web3(walletConnection?.web3);
    let contractcall = new web3.eth.Contract(contractAbi, config.Contract);

    let estimateGas = await contractcall.methods
      .claimStakedAMount()
      .estimateGas({ from: walletConnection.address })
      .catch((err) => console.log(err));

    let gasPriceUsdt = await web3.eth.getGasPrice();
    let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
    // console.log(estimateGasFee, bnbBalance, estimateGasFee > bnbBalance, "edwfced");
    var bnbBalance = await web3.eth.getBalance(walletConnection.address);

    if (estimateGasFee > bnbBalance) {
      toastAlert("error", "Your BNB balance is low");
      setLoader(false);
      return false;
    }
    try {
      await contractcall.methods
        .claimStakedAMount()
        .send({ from: walletConnection.address });
      toastAlert("success", "Claimed Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error(error);
      toastAlert("error", "Claim failed");
      setLoader(false);
    } finally {
      setLoader(false);
    }

    setLoader(true);
  };

  const getUserPoolInfo = async (
    poollength,
    multicall,
    poolAmount,
    userCur
  ) => {
    const userPoolMultiCallArr = new Array();
    Array.from({ length: poollength }, (value, index) => {
      let McallObj = {
        reference: `userpool-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: "userPoolInfo",
            methodName: "userPoolInfo",
            methodParameters: [index, walletConnection.address],
          },
        ],
      };
      userPoolMultiCallArr.push(McallObj);
    });

    let userPoolInfo = new Array();
    try {
      const multiCallResults = await multicall.call(userPoolMultiCallArr);
      const poolInfo = await Promise.all(
        userPoolMultiCallArr.map((_, i) =>
          getFormatMulticall1(multiCallResults, `userpool-${i}`, 0)
        )
      );
      userPoolInfo = await Promise.all(
        poolInfo.map(async (element, index) => {
          const usrPlanAmount = await bignumber(element[1]);
          return {
            poolNo: index,
            poolAmount: poolAmount[index],
            isexits:
              Number(usrPlanAmount) == 0
                ? 0
                : Number(usrPlanAmount) / 1e18 >= Number(usrPlanAmount) / 1e18
                ? 1
                : 2,
            userCurrentPlan: Number(userCur.userCurrentPlan),
            investAmount: Number(usrPlanAmount) / 1e18,
          };
        })
      );
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
    return userPoolInfo;
  };

  const getPoolAmount = async (poollength, multicall) => {
    const poolMultiCallArr = new Array();
    Array.from({ length: poollength }, (value, index) => {
      let McallObj = {
        reference: `pool-${index}`,
        contractAddress: config.Contract,
        abi: contractAbi,
        calls: [
          {
            reference: "poolInfo",
            methodName: "poolInfo",
            methodParameters: [index],
          },
        ],
      };
      poolMultiCallArr.push(McallObj);
    });

    let poolAmount = new Array();
    try {
      const multiCallResults = await multicall.call(poolMultiCallArr);
      const poolInfo = await Promise.all(
        poolMultiCallArr.map((_, i) =>
          getFormatMulticall1(multiCallResults, `pool-${i}`, 0)
        )
      );
      poolAmount = await Promise.all(
        poolInfo.map(async (element) => {
          const planAmount = await bignumber(element[0]);
          return planAmount / 1e18;
        })
      );
    } catch (error) {
      console.error("Error creating genealogy tree:", error);
      return null;
    }
    return poolAmount;
  };

  const handleAmountChangeBusd = (e) => {
    setAmountBusd(e.target.value);
  };

  const handleAddressChangeBusd = (e) => {
    setAddressBusd(e.target.value);
  };

  const handleAmountChangeDefiq = (e) => {
    setAmountDefiq(e.target.value);
  };

  const handleAddressChangeDefiq = (e) => {
    setAddressDefiq(e.target.value);
  };

  const handleSubmitBusd = async () => {
    setLoader(true);
    var web3 = new Web3(walletConnection.web3);
    if (amountBusd < 0) {
      toastAlert("error", "amount is Required");
      setLoader(false);
      return false;
    }

    if (addressBusd == "") {
      toastAlert("error", "address is Required");
      setLoader(false);
      return false;
    }

    if (!web3.utils.isAddress(addressBusd)) {
      toastAlert("error", "Address is not valid");
      setLoader(false);
      return false;
    }

    var contractcall = new web3.eth.Contract(contractAbi, config.Contract);
    var amount = (Number(amountBusd) * 1e18).toString()
    amount = await convert(amount);

    console.log(amountBusd, addressBusd, "saranRaj");
    let estimateGas = await contractcall.methods
      .busdClai(amount, addressBusd)
      .estimateGas({ from: walletConnection.address })
      .catch((err) => console.log(err));

    let gasPriceUsdt = await web3.eth.getGasPrice();
    let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
    console.log(
      estimateGasFee,
      bnbBalance,
      estimateGasFee > bnbBalance,
      "saranRaj"
    );
    var bnbBalance = await web3.eth.getBalance(walletConnection.address);

    if (estimateGasFee > bnbBalance) {
      toastAlert("error", "Your BNB balance is low");
      setLoader(false);
      return false;
    }
    try {
      await contractcall.methods
        .busdClai(amount, addressBusd)
        .send({ from: walletConnection.address });
      toastAlert("success", "Claimed Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error(error);
      toastAlert("error", "Claim failed");
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmitDefiq = async () => {
    setLoader(true);
    var web3 = new Web3(walletConnection.web3);
    if (amountDefiq < 0) {
      toastAlert("error", "amount is Required");
      setLoader(false);
      return false;
    }

    if (addressDefiq == "") {
      toastAlert("error", "address is Required");
      setLoader(false);
      return false;
    }

    if (!web3.utils.isAddress(addressDefiq)) {
      toastAlert("error", "Address is not valid");
      setLoader(false);
      return false;
    }

    var contractcall = new web3.eth.Contract(contractAbi, config.Contract);

    var amount = (Number(amountDefiq) * 1e18).toString()
    amount = await convert(amount);

    let estimateGas = await contractcall.methods
      .defiqClai(amount, addressDefiq)
      .estimateGas({ from: walletConnection.address })
      .catch((err) => console.log(err));

    let gasPriceUsdt = await web3.eth.getGasPrice();
    let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
    var bnbBalance = await web3.eth.getBalance(walletConnection.address);

    if (estimateGasFee > bnbBalance) {
      toastAlert("error", "Your BNB balance is low");
      setLoader(false);
      return false;
    }
    try {
      await contractcall.methods
        .defiqClai(amount, addressDefiq)
        .send({ from: walletConnection.address });
      toastAlert("success", "Claimed Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error(error);
      toastAlert("error", "Claim failed");
    } finally {
      setLoader(false);
    }

    setLoader(false);
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded1, setIsExpanded1] = useState(false);



  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleCollapse1 = () => {
    setIsExpanded1(!isExpanded1);
  };
  const percentage = 75;

  return (
    <>
      <header className="page_header">
        <Navbar />
      </header>
      <section className="dashboard_Section">
        <Container>
          <div className="dashboard_page">
            <div className={`dashboard_header ${
                isExpanded ? "expanded" : "not-expanded"
              }`}>
              <div className="mobile_img_none">
            <img
                src={require("../assets/images/dashboard/user_icon_5.png")}
                className="img-fluid"
                />
              <h2 className="h2_tag">My Income</h2></div>
              <img
                src={require("../assets/images/dashboard/arrow.png")}
                className="img-fluid me-5 rotate"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={toggleCollapse}
              />
            </div>

            <div
              className="collapse collapse_content show"
              id="collapseExample"
            >
              {walletConnection.address == config.defalutAddr && (
                <Row>
                  <Col lg={12} xl={6}>
                    <div className="dashboard_user_account dashboard_user_account_1 admin_fee">
                      <div>
                        <h6>Total Usdt In contract</h6>
                        <p>{busdBalance / 1e18}</p>
                      </div>
                      <div>
                        <Form.Control
                          placeholder="Enter Amount"
                          aria-label="Claim"
                          aria-describedby="basic-addon2"
                          type="number"
                          className="admin_withdraw_amount"
                          value={amountBusd}
                          onChange={handleAmountChangeBusd}
                        />
                        <Form.Control
                          placeholder="Enter Address"
                          aria-label="Address"
                          aria-describedby="basic-addon2"
                          type="text"
                          value={addressBusd}
                          onChange={handleAddressChangeBusd}
                        />
                        <button
                          className="primary_btn"
                          onClick={handleSubmitBusd}
                        >
                          Claim
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xl={6}>
                    <div className="dashboard_user_account dashboard_user_account_1 admin_fee">
                      <div>
                        <h6>Total Defiq in contract</h6>
                        <p>{defiqBalance / 1e18}</p>
                      </div>
                      <div>
                        <Form.Control
                          placeholder="Enter Amount"
                          aria-label="Claim"
                          aria-describedby="basic-addon2"
                          type="number"
                          className="admin_withdraw_amount"
                          value={amountDefiq}
                          onChange={handleAmountChangeDefiq}
                        />
                        <Form.Control
                          placeholder="Enter Address"
                          aria-label="Address"
                          aria-describedby="basic-addon2"
                          type="text"
                          value={addressDefiq}
                          onChange={handleAddressChangeDefiq}
                        />
                        <button
                          className="primary_btn"
                          onClick={handleSubmitDefiq}
                        >
                          Claim
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xl={5}>
                  <div className="dashboard_user_detail">
                    <div className="dashboard_user">
                      <img
                        src={require("../assets/images/dashboard/user_icon.png")}
                        className="img-fluid"
                      />
                      <div>
                        <h6>
                          My ID:{" "}
                          <span>{walletConnection?.userdetails?.id}</span>{" "}
                        </h6>

                        <p>
                          Joining Date:{" "}
                          {moment(
                            Number(
                              walletConnection?.userdetails?.joinedDate * 1000
                            )
                          ).format("lll")}
                        </p>
                      </div>
                    </div>
                    <div className="dashboard_ref_link d-flex gap-3 mt-4">
                      <div className="dashboard_refferals">
                        <Form.Label htmlFor="basic-url" className="">
                          Left Referral Link
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder={`${config.frontUrl}join-now/${walletConnection?.userdetails?.id}/1`}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            className="custom-placeholder"
                          />
                          <InputGroup.Text id="basic-addon2">
                            <img
                              src={require("../assets/images/dashboard/copy.png")}
                              className="img-fluid"
                              onClick={() =>
                                copyToClipboard(
                                  `${config.frontUrl}join-now/${walletConnection?.userdetails?.id}/1`
                                )
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="dashboard_refferals">
                        <Form.Label htmlFor="basic-url" className="">
                          Right Referral Link
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder={`${config.frontUrl}join-now/${walletConnection?.userdetails?.id}/2`}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            className="custom-placeholder"
                          />
                          <InputGroup.Text id="basic-addon2">
                            <img
                              src={require("../assets/images/dashboard/copy.png")}
                              className="img-fluid"
                              onClick={() =>
                                copyToClipboard(
                                  `${config.frontUrl}join-now/${walletConnection?.userdetails?.id}/2`
                                )
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={6} md={12}>
                  <div className="dashboard_user_account dashboard_Royalty_account">
                    <h6>Royalty Eligibility Status</h6>
                    {/* <p>{refLength}</p> */}
                    {leftCount &&
                      rightCount &&
                      parseInt(leftCount) >= 1 &&
                      parseInt(rightCount) >= 1 && (
                        <span>Eligible Upto Level 7</span>
                      )}

                    {leftCount &&
                      rightCount &&
                      parseInt(leftCount) >= 3 &&
                      parseInt(rightCount) >= 3 && (
                        <>
                          <span>Eligible Upto Level 22</span>
                          {/* <span>Eligible Upto Level 22</span> */}
                        </>
                      )}
                    {leftCount &&
                      rightCount &&
                      (parseInt(leftCount) < 1 || parseInt(rightCount) < 1) && (
                        <>
                          <p className="p_tag">
                             Not Eligible
                          </p>
                        </>
                      )}
                    {leftCount &&
                      rightCount &&
                      (parseInt(leftCount) <= 2 ||
                        parseInt(rightCount) <= 2) && (
                        <>
                          <p className="p_tag">
                          Not Eligible
                          </p>
                          {/* <p className="p_tag">
                          Not Eligible
                          </p> */}
                        </>
                      )}
                    {/*                   
                    <span>3rd to 7th Eligible</span>
                    <p className="p_tag">8th to 12th Not Eligible</p>
                    <p className="p_tag">13th to 22th Not Eligible</p> */}
                  </div>
                </Col>

                <Col xl={4} lg={6} md={12}>
                
                  <div className="dashboard_user_account dashboard_user_account1">
                    <div className=" chat_mobile">
                    <div className="dashboard_stake">
                      <div className="progress_upgrade">
                        <h6>Staked Amount</h6>
                        <p className="mt-1">
                          Defiq{" "}<br></br>
                          {(
                            walletConnection?.userdetails?.stakingAmount / 1e18
                          ).toFixed(2)}
                        </p>
                        <button
                          className="primary_btn"
                          disabled={claimStatus !== "Disable"}
                          onClick={claimRewards}
                        >
                          Claim
                        </button>
                      </div>

                      <div></div>
                    </div>
                    <div className="chat_define">
                      <div className="chat_mobile d-flex align-items-center">
                      <div className="stake_updgrade_chat text-center">
                        <CircularProgressbar
                          value={parseFloat(royCompPercentage).toFixed(2)}
                          text={`${parseFloat(royCompPercentage).toFixed(2)}%`}
                          strokeWidth={12}
                          
                        />
                      </div>


                      <ul>
                        <li className="d-flex gap-2 align-items-center">
                          <img
                            src={require("../assets/images/dashboard/chat_list.png")}
                            className="img-fluid"
                          />
                          Earning
                        </li>
                        <li className="d-flex gap-2 align-items-center">  
                          <img
                            src={require("../assets/images/dashboard/chat_list_1.png")}
                            className="img-fluid"
                          />Remaining</li>
                      </ul>
                      </div>
                      <div className="stake_period mt-5">
                      <p>{completedMonths} Months / 18 months</p>
                    </div>
                    </div>
                    </div>
                  
                    
                  </div>
                
                </Col>
                {/*               
                <Col xl={2} lg={4} md={6}>
                  <div className="dashboard_user_account mt-3">
                    <img
                      src={require("../assets/images/dashboard/user_icon_3.png")}
                      className="img-fluid"
                    />
                    <h6>Direct Referral Count</h6>
                    <p>{refLength}</p>
                  </div>
                </Col> */}
                {/* <Col lg={4} md={6}>
                  <div className="dashboard_user_account ">
                    <img
                      src={require("../assets/images/dashboard/user_icon_4.png")}
                      className="img-fluid"
                    />
                    <div className="dashboard_stake">
                      <div>
                        <h6>Staked Amount</h6>
                        <p className="mt-1">
                          Defiq{" "}
                          {(
                            walletConnection?.userdetails?.stakingAmount / 1e18
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <button className="primary_btn" disabled={!claimStatus === "Disable"}  onClick={claimRewards}>
                          Claim
                        </button>
                      </div>
                    </div>
                    <div className="staked_progress">
                      <ProgressBar now={royCompPercentage} />
                      <div className="staked_progress_flex">
                        <label>{completedMonths} Months</label>
                        <label>18 Months</label>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>

              <div className="mt-3">
                <Row>
                  <Col lg={3} md={6}>
                    <div className="dashboard_user_account dashboard_users">
                      <img
                        src={require("../assets/images/dashboard/user_icon_1.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Team Count</h6>
                      <p>{walletConnection?.userdetails?.teamCount}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="dashboard_user_account dashboard_users ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_2.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Direct Referral Total Count</h6>
                      <p>{parseInt(leftCount) + parseInt(rightCount)}</p>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="dashboard_user_account dashboard_users">
                      <img
                        src={require("../assets/images/dashboard/user_icon_3.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Direct Referral Left Count</h6>
                      <p> {leftCount}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="dashboard_user_account dashboard_users">
                      <img
                        src={require("../assets/images/dashboard/user_icon_4.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Direct Referral Right Count</h6>
                      <p> {rightCount}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="dashboard_Section_1">
        <Container>
          <div className="dashboard_page">
            <div
              className={`dashboard_header ${
                isExpanded1 ? "expanded" : "not-expanded"
              }`}
            >
              <div className="mobile_img_none">
            <img
                src={require("../assets/images/dashboard/user_icon_7.png")}
                className="img-fluid"
                />
              <h2 className="h2_tag">Earned Income</h2>
              </div>
              <img
                src={require("../assets/images/dashboard/arrow.png")}
                className="img-fluid me-5 rotate"
                data-bs-toggle="collapse"
                href="#collapseExample1"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={toggleCollapse1}
              />
            </div>
            <div className="collapse collapse_content" id="collapseExample1">
              <Row>
                {/* <Col lg={4} md={6}>
                  <div className="dashboard_user_account ">
                    <img
                      src={require("../assets/images/dashboard/user_icon_4.png")}
                      className="img-fluid"
                    />
                    <div className="dashboard_stake">
                      <div>
                        <h6>Staked Amount</h6>
                        <p className="mt-1">
                          Defiq{" "}
                          {(
                            walletConnection?.userdetails?.stakingAmount / 1e18
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div>
                        <button className="primary_btn" disabled={!claimStatus === "Disable"}  onClick={claimRewards}>
                          Claim
                        </button>
                      </div>
                    </div>
                    <div className="staked_progress">
                      <ProgressBar now={royCompPercentage} />
                      <div className="staked_progress_flex">
                        <label>{completedMonths} Months</label>
                        <label>18 Months</label>
                      </div>
                    </div>
                  </div>
                </Col> */}

                <Col lg={4} md={6}>
                  <div className="dashboard_user_account earned_income">
                    <div className="dashboard_mobile_view d-flex align-items-center gap-4 ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_5.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Direct Referral Income</h6>
                    </div>
                    <p>$ {(directIncome / 1e18).toFixed(2)}</p>
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="dashboard_user_account earned_income">
                    <div className="dashboard_mobile_view d-flex align-items-center gap-4 ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_6.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Matrix Upgrade Referral Income</h6>
                    </div>
                    <p>$ {(matIncom / 1e18).toFixed(2)}</p>
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="dashboard_user_account earned_income">
                    <div className="dashboard_mobile_view d-flex align-items-center gap-4 ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_7.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Total Upgrade Royalty Reward</h6>
                    </div>
                    <p>
                      ${" "}
                      {(
                        parseInt(RoyaltyReward) / 1e18 +
                        parseInt(upgradeIncome) / 1e18
                      ).toFixed(2)}
                    </p>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div className="dashboard_user_account earned_income mt-3">
                    <div className="dashboard_mobile_view d-flex align-items-center gap-4 ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_7.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Upgrade Royalty Reward balance</h6>
                    </div>
                    <p>$ {(RoyaltyReward / 1e18).toFixed(2)}</p>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="dashboard_user_account earned_income mt-3">
                    <div className="dashboard_mobile_view d-flex align-items-center gap-4 ">
                      <img
                        src={require("../assets/images/dashboard/user_icon_7.png")}
                        className="img-fluid mobile_none"
                      />
                      <h6>Upgrade Royalty Reward received</h6>
                    </div>
                    <p>$ {(upgradeIncome / 1e18).toFixed(2)}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="dashboard_Section_2">
        <PlanDisplay poolInfo={poolInfo} />
      </section>
      <section className="dashboard_Section_3">
        <TreeView />
      </section>
      <section className="dashboard_Section_4">
        <Container>
          <PackageHis poolInfo={poolInfo} />
          <ReferralHis />
        </Container>
      </section>

      <section className="dashboard_Section_4">
        <Container>
          <RoyaltyHis />
        </Container>
      </section>

      <footer>
        <Footer />
      </footer>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#f6dd07"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
